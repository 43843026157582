<template lang="pug">
    .dialogProcedimento

        BuscarProcedimento

        Panel(header='Adicionar procedimento')

            form.form-procedimento(@submit.prevent='save()')
                .p-grid.p-fluid.p-align-end

                    .p-col-12

                        Panel(header='Grupo de procedimentos')

                            .p-grid.p-fluid.p-justify-center(v-if='model.cd_procedimentos')

                                .p-col-12.ta-right(v-if='model.cd_procedimentos.length && $parent.isOrcamento' style="display: flex; align-items: flex-end; justify-content: right; margin-right: 5px")
                                    label.form-label Desconto manual:
                                    InputSwitch.ml-2(type="boolean" v-model='ie_desconto_manual' @click='change_desconto_manual()')
                                .p-col-12(v-if='model.cd_procedimentos.length')
                                    DataTable(:value='model.cd_procedimentos' editMode='cell' 
                                        class='editable-cells-table responsive'	 @cell-edit-complete='onCellEditComplete' selectionMode='single' )
                                        Column(headerStyle='width: 60%' header='Procedimento' field='ds_observacao')
                                        Column(headerStyle='width: 10%'   bodyStyle='text-align: center' header='Valor Negociado')
                                            template(#editor='props') 
                                                div( :class='props.data.id? "click-div":""')
                                                    currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled(
                                                        v-model="props.data['nr_valor_negociado']"
                                                        v-tooltip.top='!ie_desconto_manual? "Desconto manual desativado":"Desconto manual ativado"'
                                                        :disabled='!ie_desconto_manual'
                                                        locale='pt-BR'
                                                        :auto-decimal-mode='true'
                                                        :value-range='{ min: 0 }'
                                                        :class='props.data.id? "click-div":""' )
                                                    
                                            template(#body='props')
                                                div.click-div(:class="{edit: ie_desconto_manual? true : false}")
                                                    span.d-block.o-hidden {{props.data.nr_valor_negociado_f}}
                                                    em(v-if='props.data.nr_valor_negociado_original_f != props.data.nr_valor_negociado_f' style='font-size: 90%; color: #fd6161') {{props.data.nr_valor_negociado_original_f}}


                                        Column(headerStyle='width: 10%' bodyStyle='text-align: center' header='Valor Tarifa')
                                            template(#editor='props')
                                                currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled(
                                                    v-model="props.data['nr_valor_tarifa']"
                                                    v-tooltip.top='!ie_desconto_manual? "Desconto manual desativado":"Desconto manual ativado"'
                                                    :disabled='!ie_desconto_manual'
                                                    locale='pt-BR'
                                                    :auto-decimal-mode='true'   
                                                    :value-range='{ min: 0 }')

                                            template(#body='props')
                                                div.click-div(:class="{edit: ie_desconto_manual? true : false}")
                                                    span.d-block.o-hidden {{props.data.nr_valor_tarifa_f}}
                                                    em(v-if='props.data.nr_valor_taxa_original_f != props.data.nr_valor_tarifa_f' style='font-size: 90%; color: #fd6161') {{props.data.nr_valor_taxa_original_f}}

                                        Column(header='Total' headerStyle='width: 10%'   bodyStyle='text-align: center')
                                            template(#body='props')
                                                div
                                                    span.d-block.o-hidden {{ $root.formatPrice(props.data.nr_valor_negociado + props.data.nr_valor_tarifa)}}
                                                    em(v-if='(props.data.nr_valor_negociado + props.data.nr_valor_tarifa) != (props.data.nr_valor_negociado_original + props.data.nr_valor_taxa_original)' style='font-size: 90%; color: #fd6161') {{ $root.formatPrice(props.data.nr_valor_negociado_original + props.data.nr_valor_taxa_original)}}

                                        Column(header='Ações')
                                            template(#body='props')
                                                .ta-center
                                                    Button.p-button-raised.p-button-rounded.p-button-danger(v-if='props.data.id!=0' type='button' icon="jam jam-trash" @click='removerProcedimento(props)')
                                                   
                                        ColumnGroup(v-if='model.cd_procedimentos.length > 1 && $parent.isOrcamento' type="footer")
                                            Row
                                                Column(footer="Total" footerStyle="text-align: right")
                                                Column(footerStyle="text-align: center")
                                                    template(#footer='props')
                                                        div 
                                                            span.d-block.o-hidden {{nr_valor_negociado_f}}
                                                            em(v-if='nr_valor_negociado_original_f != nr_valor_negociado_f' style='font-size: 90%; color: #fd6161') {{nr_valor_negociado_original_f}}
                                                Column(footerStyle="text-align: center")
                                                    template(#footer='props')
                                                        div 
                                                            span.d-block.o-hidden {{nr_valor_tarifa_f}}
                                                            em(v-if='nr_valor_taxa_original_f != nr_valor_tarifa_f' style='font-size: 90%; color: #fd6161') {{nr_valor_taxa_original_f}}
                                                
                                                Column( footerStyle="text-align: center")
                                                    template(#footer='props')
                                                        div 
                                                            span.d-block.o-hidden {{total}}
                                                            em(v-if='total != totalOrigiginal' style='font-size: 90%; color: #fd6161') {{totalOrigiginal}}
                                                

                                .p-col-12.p-md-2.pt-2
                                    Button(icon='jam jam-search' label='Buscar procedimento' style='font-size:12px' type='button' @click='dialogBuscar = true')

                    .p-col-12.p-md-2
                        label.form-label Hospital:
                        .p-inputgroup
                            Checkbox.mr-1(
                                v-if="!isOrcamento"
                                v-model="model.ie_mostrar_hospital"
                                v-tooltip.top="'Mostrar no App'" :binary="true"
                                style="align-self: center")
                            span.p-inputgroup-addon R$
                            span
                                currency-input.input-adicionar.p-inputnumber-input.p-inputtext.p-component.p-filled(
                                    v-model='model.nr_valor_hospital' :disabled='ie_desconto_manual' locale='pt-BR' :auto-decimal-mode='true' :value-range='{ min: 0 }')

                    .p-col-12.p-md-2
                        label.form-label Material cirúrgico:
                        .p-inputgroup
                            Checkbox.mr-1(
                                v-if="!isOrcamento"
                                v-model="model.ie_mostrar_material_cirurgico"
                                v-tooltip.top="'Mostrar no App'" :binary="true"
                                style="align-self: center")
                            span.p-inputgroup-addon R$
                            span
                                currency-input.input-adicionar.p-inputnumber-input.p-inputtext.p-component.p-filled(
                                    v-model='model.nr_material_cirurgico' locale='pt-BR' :disabled='$parent.$parent.model.ie_recebivel' :auto-decimal-mode='true' :value-range='{ min: 0 }')

                    .p-col-12.p-md-2
                        label.form-label Anestesia:
                        .p-inputgroup
                            Checkbox.mr-1(
                                v-if="!isOrcamento"
                                v-model="model.ie_mostrar_anestesista"
                                v-tooltip.top="'Mostrar no App'" :binary="true"
                                style="align-self: center")
                            span.p-inputgroup-addon R$
                            span
                                currency-input.input-adicionar.p-inputnumber-input.p-inputtext.p-component.p-filled(
                                    v-model='model.nr_valor_anestesista' locale='pt-BR' :disabled='$parent.$parent.model.ie_recebivel' :auto-decimal-mode='true' :value-range='{ min: 0 }')

                    .p-col-12.p-md-2
                        label.form-label Valor OPME:
                        .p-inputgroup
                            Checkbox.mr-1(
                                v-if="!isOrcamento"
                                v-model="model.ie_mostrar_opme"
                                v-tooltip.top="'Mostrar no App'" :binary="true"
                                style="align-self: center")
                            span.p-inputgroup-addon R$
                            span
                                currency-input.input-adicionar.p-inputnumber-input.p-inputtext.p-component.p-filled(
                                    v-model='model.nr_valor_opme' locale='pt-BR' :disabled='$parent.$parent.model.ie_recebivel' :auto-decimal-mode='true' :value-range='{ min: 0 }')

                    .p-col-12.p-md-4
                        label.form-label Descrição OPME:
                        InputText(type='text' v-model='model.ds_opme_descricao')

                    .p-col-12.p-md-2
                        label.form-label Repasse:
                        .p-inputgroup
                            Checkbox.mr-1(
                                v-if="!isOrcamento"
                                v-model="model.ie_mostrar_repasse"
                                v-tooltip.top="'Mostrar no App'" :binary="true"
                                style="align-self: center")
                            span.p-inputgroup-addon R$
                            span
                                currency-input.input-adicionar.p-inputnumber-input.p-inputtext.p-component.p-filled(
                                    v-model='model.nr_valor_repasse' locale='pt-BR' :disabled='$parent.$parent.model.ie_recebivel' :auto-decimal-mode='true' :value-range='{ min: 0 }')

                    .p-col-12.p-md-2
                        label.form-label Perfusão:
                        .p-inputgroup
                            Checkbox.mr-1(
                                v-if="!isOrcamento"
                                v-model="model.ie_mostrar_perfusao"
                                v-tooltip.top="'Mostrar no App'" :binary="true"
                                style="align-self: center")
                            span.p-inputgroup-addon R$
                            span
                                currency-input.input-adicionar.p-inputnumber-input.p-inputtext.p-component.p-filled(
                                    v-model='model.nr_valor_perfusao' locale='pt-BR' :disabled='$parent.$parent.model.ie_recebivel' :auto-decimal-mode='true' :value-range='{ min: 0 }')

                    .p-col-12.p-md-2
                        label.form-label Fisioterapia:
                        .p-inputgroup
                            Checkbox.mr-1(
                                v-if="!isOrcamento"
                                v-model="model.ie_mostrar_fisioterapeuta"
                                v-tooltip.top="'Mostrar no App'" :binary="true"
                                style="align-self: center")
                            span.p-inputgroup-addon R$
                            span
                                currency-input.input-adicionar.p-inputnumber-input.p-inputtext.p-component.p-filled(
                                    v-model='model.nr_valor_fisioterapeuta' locale='pt-BR' :disabled='$parent.$parent.model.ie_recebivel' :auto-decimal-mode='true' :value-range='{ min: 0 }')

                    .p-col-12.p-md-2
                        label.form-label Taxa:
                        .p-inputgroup
                            Checkbox.mr-1(
                                v-if="!isOrcamento"
                                v-model="model.ie_mostrar_taxa"
                                v-tooltip.top="'Mostrar no App'" :binary="true"
                                style="align-self: center")
                            span.p-inputgroup-addon R$
                            span
                                currency-input.input-adicionar.p-inputnumber-input.p-inputtext.p-component.p-filled(
                                    v-model='model.nr_valor_taxa' :disabled='ie_desconto_manual' locale='pt-BR'  :auto-decimal-mode='true' :value-range='{ min: 0 }')

                    .p-col-12.p-md-4
                        label.form-label Descrição taxa:
                        InputText(type='text' v-model='model.ds_taxa_descricao')

                    .p-col-12.p-md-2
                        label.form-label Hemoderivados:
                        .p-inputgroup
                            Checkbox.mr-1(
                                v-if="!isOrcamento"
                                v-model="model.ie_mostrar_hemoderivados"
                                v-tooltip.top="'Mostrar no App'" :binary="true"
                                style="align-self: center")
                            span.p-inputgroup-addon R$
                            span
                                currency-input.input-adicionar.p-inputnumber-input.p-inputtext.p-component.p-filled(
                                    v-model='model.nr_valor_hemoderivados' locale='pt-BR' :disabled='$parent.$parent.model.ie_recebivel' :auto-decimal-mode='true' :value-range='{ min: 0 }')

                    .p-col-12.p-md-4
                        label.form-label Descrição hemoderivados:
                        InputText(type='text' v-model='model.ds_hemoderivados_descricao')

                    .p-col-12.p-md-2
                        label.form-label Exames:
                        .p-inputgroup
                            Checkbox.mr-1(
                                v-if="!isOrcamento"
                                v-model="model.ie_mostrar_exames"
                                v-tooltip.top="'Mostrar no App'" :binary="true"
                                style="align-self: center")
                            span.p-inputgroup-addon R$
                            span
                                currency-input.input-adicionar.p-inputnumber-input.p-inputtext.p-component.p-filled(
                                    v-model='model.nr_valor_exames' locale='pt-BR' :disabled='$parent.$parent.model.ie_recebivel' :auto-decimal-mode='true' :value-range='{ min: 0 }')

                    .p-col-12.p-md-4
                        label.form-label Descrição exames:
                        InputText(type='text' v-model='model.ds_exames_descricao')

                    .p-col-12.p-md-2
                        label.form-label Honorários:
                        .p-inputgroup
                            Checkbox.mr-1(
                                v-if="!isOrcamento"
                                v-model="model.ie_mostrar_honorario"
                                v-tooltip.top="'Mostrar no App'" :binary="true"
                                style="align-self: center")
                            span.p-inputgroup-addon R$
                            span
                                currency-input.input-adicionar.p-inputnumber-input.p-inputtext.p-component.p-filled(
                                    v-model='model.nr_valor_honorario' locale='pt-BR' :auto-decimal-mode='true' :value-range='{ min: 0 }')

                    .p-col-12.p-md-2
                        label.form-label Porte:
                        Dropdown(v-model='model.ie_porte' :options='porte' dataKey='key'
                            optionLabel='label' optionValue='key' placeholder='Selecione...')

                    .p-col-12.p-md-4(:class="{ 'form-group--error': $v.model.cd_especialista.$error || $v.model.nm_especialista.$error }")
                        label.form-label(v-if="!isOrcamento") Especialista:
                        div(v-else style="display: flex; align-items: baseline")
                            label.form-label Especialista (
                            span.choice-especialist(:class="{active: isEspecialistaCadastrado}" @click="isEspecialistaCadastrado=true") Cadastrado
                            label.form-label.mx-1 /
                            span.choice-especialist(:class="{active: !isEspecialistaCadastrado}" @click="isEspecialistaCadastrado=false") Sem Cadastro
                            label.form-label ):

                        InputText(v-if="!isEspecialistaCadastrado && isOrcamento" type='text' v-model='model.nm_especialista')
                        div(v-else)
                            ProgressBar(v-if='$parent.$parent.waitingEspecialista' mode="indeterminate")
                            Dropdown(v-else v-model='model.cd_especialista' :options='$parent.$parent.especialistas' dataKey='id'
                                optionLabel='nm_especialista' :filter="true" optionValue='id' placeholder='Selecione...' showClear)
                        .feedback--errors(v-if='$v.model.cd_especialista.$error || $v.model.nm_especialista.$error')
                            .form-message--error(v-if="!$v.model.cd_especialista.required || !$v.model.nm_especialista.required") Campo obrigatório.

                    .p-col-12.ta-center
                        p.text-title.ma-0 <b>Acomodações</b>

                    .p-col-12.p-md-2
                        label.form-label Apt. IND.:
                        InputNumber(type='text' v-model='model.qtd_diaria_apartamento_individual')

                    .p-col-12.p-md-2
                        label.form-label Apt. DUPLO:
                        InputNumber(type='text' v-model='model.qtd_diaria_apartamento_duplo')

                    .p-col-12.p-md-2
                        label.form-label UTI:
                        InputNumber(type='text' v-model='model.qtd_diaria_uti')

                    .p-col-12.p-md-2
                        label.form-label Day clinic/EXTERNO:
                        InputNumber(type='text' v-model='model.qtd_diaria_dia_clinica')

                    .p-col-12.ta-center
                        Button.p-button-warning.mr-2(style='max-width:240px;font-size:12px' type='button' label='Cancelar' @click='$parent.$parent.formProcedimento = false')
                        Button(style='max-width:240px;font-size:12px' label="Salvar")
</template>

<style lang="scss">
.dialogProcedimento {
    .form-procedimento {
        .form-label,
        .p-inputgroup-addon,
        .p-inputtext,
        .text-title {
            font-size: 0.96em;
        }
    }
    .choice-especialist {
        font-weight: bold;
        cursor: pointer;
        color: #bbb;
        font-size: 0.85em;
        &.active {
            color: #116fbf;
            font-size: 1em;
        }
    }
    .click-div {
        pointer-events: none;
        &.edit {
            border-bottom: 0.1px gray dotted;
        }
        
    }
}
</style>

<script>
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import InputSwitch from "primevue/inputswitch";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Checkbox from "primevue/checkbox";
import Column from "primevue/column";
import Panel from "primevue/panel";
import Dropdown from "primevue/dropdown";
import ProgressSpinner from "primevue/progressspinner";
import InputNumber from "primevue/inputnumber";
import Calendar from "primevue/calendar";
import BuscarProcedimento from "./BuscarProcedimento";
import ProgressBar from "primevue/progressbar";
import Tooltip from "primevue/tooltip";
import MultiSelect from "primevue/multiselect";
import ColumnGroup from 'primevue/columngroup'
import Row from 'primevue/row'


import { pCalendarLocale_ptbr } from "./../../utils";
import { requiredIf } from "vuelidate/lib/validators";
import moment from "moment";

export default {
    components: {
        Dialog,
        InputText,
        InputSwitch,
        Button,
        DataTable,
        Checkbox,
        Column,
        BuscarProcedimento,
        ProgressSpinner,
        Panel,
        Dropdown,
        InputNumber,
        Calendar,
        ProgressBar,
        MultiSelect,
        ColumnGroup,
        Row
    },
    directives: { tooltip: Tooltip },
    computed: {
        total: {
            get(){
                return this.$root.formatPrice(this.model.cd_procedimentos.reduce((acc, item) => {
                        return acc + +item.nr_valor_negociado + +item.nr_valor_tarifa
                }, 0))
            }
        },
        totalOrigiginal:{
            get(){
                return this.$root.formatPrice(this.model.ar_precos_originais?.reduce((acc, item) => {
                    return acc + +item.nr_valor_taxa_original + +item.nr_valor_negociado_original
                }, 0))
            }
        }
    },
    watch: {
        "$parent.model.cd_estabelecimento": function(val) {
            this.filters.cd_estabelecimento = val;
        },
        "$parent.$parent.model.ie_recebivel": function(val) {
            if(val) this.attRecebiveis();
        },
        model: function(val) {
            this.attChange()
            this.isEspecialistaCadastrado = !val.nm_especialista || !!val.cd_especialista;
            this.ar_itens_recebiveis = val.ar_itens_recebiveis?.map(item => (this.options.ar_itens_recebiveis.find(i => item == i.name).value));
            if(this.nr_restore.hospital == 0) this.nr_restore.hospital = this.model.nr_valor_hospital
            if(this.nr_restore.taxa == 0) this.nr_restore.taxa = this.model.nr_valor_taxa
            if(!([undefined, null]).includes(val.ie_desconto_manual)) this.ie_desconto_manual = val.ie_desconto_manual
        }
    },
    mounted() {
        if (this.$route.query.orcamento) this.isOrcamento = true;
    },
    data() {
        return {
            model: {},
            ie_desconto_manual: false,
            nr_valor_tarifa_f: 0,
            nr_valor_negociado_f: 0,
            nr_valor_negociado_original:0,
            nr_valor_taxa_original: 0,
            nr_valor_negociado_original_f: 0,
            nr_valor_taxa_original_f: 0,
            ar_itens_recebiveis: [],
            nr_restore:{
                hospital: 0,
                taxa: 0
            },
            filters: {
                cd_estabelecimento: null,
                ds_procedimento: null
            },
            dialogBuscar: false,
            isOrcamento: false,
            isEspecialistaCadastrado: true,
            ptbr: pCalendarLocale_ptbr,
            minDate: moment().add(1, "days")._d,
            porte: [
                { key: "P", label: "Pequeno" },
                { key: "M", label: "Médio" },
                { key: "G", label: "Grande" }
            ],
            options: {
                ar_itens_recebiveis: [
                    { label:'Hospital', name: 'nr_valor_hospital', value: 0 },
                    { label: 'Material cirúrgico', name: 'nr_material_cirurgico', value: 1 },
                    { label:'Anestesia', name: 'nr_valor_anestesista', value: 2 },
                    { label: 'Valor OPME', name: 'nr_valor_opme', value: 3 },
                    { label:'Repasse', name: 'nr_valor_repasse', value: 4 },
                    { label:'Perfusão', name: 'nr_valor_perfusao', value: 5 },
                    { label:'Fisioterapia', name: 'nr_valor_fisioterapeuta', value: 6 },
                    { label:'Taxa', name: 'nr_valor_taxa', value: false },
                    { label:'Hemoderivados', name: 'nr_valor_hemoderivados', value: 7 },
                    { label:'Exames', name: 'nr_valor_exames', value: 8 },
                    { label:'Honorários', name: 'nr_valor_honorario', value: 9 },
            ]
            }
        };
    },
    validations() {
        let v = {
            model: {
                cd_especialista: {
                    required: requiredIf(
                        () => this.isOrcamento && this.isEspecialistaCadastrado
                    )
                },
                nm_especialista: {
                    required: requiredIf(
                        () => this.isOrcamento && !this.isEspecialistaCadastrado
                    )
                }
            }
        };
        return v;
    },
    methods: {
        formatPrice (val) {
            return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
        },
        reset() {
            this.model = {
                ar_itens_recebiveis: [],
                cd_procedimentos: [],
                ds_descricao: "",
                qtd_procedimento: 0,
                ie_porte: "G",
                nr_valor_fisioterapeuta: 0,
                nr_valor_honorario: 0,
                nr_valor_repasse: 0,
                nr_valor_hospital: 0,
                nr_valor_anestesista: 0,
                nr_valor_perfusao: 0,
                nr_material_cirurgico: 0,
                qtd_diaria_uti: 0,
                qtd_diaria_apartamento_duplo: 0,
                qtd_diaria_apartamento_individual: 0,
                qtd_diaria_dia_clinica: 0,
                nr_valor_opme: 0,
                ds_opme_descricao: null,
                nr_valor_taxa: 0,
                ds_taxa_descricao: null,
                nr_valor_exames: 0,
                ds_exames_descricao: null,
                nr_valor_hemoderivados: 0,
                ds_hemoderivados_descricao: null,
                cd_especialista: null,
                nm_especialista: "",
                ie_mostrar_opme: false,
                ie_mostrar_taxa: false,
                ie_mostrar_fisioterapeuta: false,
                ie_mostrar_honorario: true,
                ie_mostrar_repasse: false,
                ie_mostrar_hospital: true,
                ie_mostrar_anestesista: true,
                ie_mostrar_perfusao: false,
                ie_mostrar_exames: false,
                ie_mostrar_hemoderivados: false,
                ie_mostrar_material_cirurgico: false,
                ie_desconto_manual: false
            };
            this.isEspecialistaCadastrado = true;
            
            this.$v.$reset();
        },
        save() {
            this.$v.$touch();
            if (this.$v.$invalid) return;

            let check = true;
            let validateFields = [
                "ar_itens_recebiveis",
                "ie_porte",
                "nr_valor_opme",
                "nr_valor_taxa",
                "nr_valor_anestesista",
                "nr_valor_exames",
                "nr_valor_hemoderivados",
                "nr_valor_fisioterapeuta",
                "nr_valor_honorario",
                "nr_valor_repasse",
                "nr_valor_hospital",
                "nr_valor_perfusao",
                "qtd_diaria_uti",
                "qtd_diaria_apartamento_duplo",
                "qtd_diaria_apartamento_individual",
                "qtd_diaria_dia_clinica"
            ];

            Object.keys(this.model).forEach(key => {
                if (validateFields.includes(key)) {
                    if (!this.model[key] && this.model[key] != 0) check = false;
                }
            });

            if (!this.model.cd_procedimentos.length) {
                this.$toast.error(`Adicione pelo menos 1 procedimento`, {
                    duration: 3000
                });
                return 0;
            }
            this.model.cd_procedimentos = this.model.cd_procedimentos.filter(item => item.id != 0)
            if (!check) {
                this.$toast.error(`Preencha todos os campos`, {
                    duration: 3000
                });
                return 0;
            }

            let procedimento = Object.assign({}, this.model);
            procedimento.ie_desconto_manual = this.ie_desconto_manual
            procedimento.ar_itens_recebiveis = this.ar_itens_recebiveis?.map(item => (this.options.ar_itens_recebiveis.find(i => i.value == item).name))
            this.$parent.$parent.aux_ar_itens_recebiveis = this.ar_itens_recebiveis;

            procedimento.qtd_procedimento =
                procedimento.cd_procedimentos.length;
            procedimento.ds_descricao = "";

            procedimento.cd_procedimentos.forEach(p => {
                if (procedimento.ds_descricao.length)
                    procedimento.ds_descricao += "; ";
                procedimento.ds_descricao += p.ds_observacao;
            });

            if (this.isEspecialistaCadastrado) {
                procedimento.cd_especialista = this.$parent.$parent.especialistas.find(
                    e => e.id == procedimento.cd_especialista
                );
                procedimento.nm_especialista =
                    procedimento.cd_especialista?.nm_especialista || "";
            } else {
                procedimento.cd_especialista = null;
            }

            if (procedimento._idx === undefined)
                this.$parent.$parent.model.procedimentos.push(procedimento);
            else {
                let idx = procedimento._idx;
                delete procedimento._idx;
                let procedimentos = Object.assign(
                    [],
                    this.$parent.$parent.model.procedimentos
                );
                procedimentos[idx] = procedimento;
                
                this.$parent.$parent.model.procedimentos = procedimentos;
            }
            this.$parent.$parent.formProcedimento = false;
        },
        removerProcedimento(procedimento) {
            //Alteração feita para inserir mais de um procedimento igual
            this.model.ar_precos_originais.splice(procedimento.index, 1);
            this.model.cd_procedimentos.splice(procedimento.index, 1);
            this.attChange()
            this.attHospTaxa();
        },
        //Função que estava sendo usada antes da atividade TM-876
        // removerProcedimento(procedimento) {
        //     this.model.cd_procedimentos.splice(procedimento.index, 1);
        // },
        onCellEditComplete (ev) {
            //Alteração feita para inserir mais de um procedimento igual
            if (this.formatPrice(ev.data.nr_valor_negociado) !== ev.data.nr_valor_negociado_f)
                this.model.cd_procedimentos[ev.index].nr_valor_negociado_f = this.formatPrice(ev.data.nr_valor_negociado)          
            if (this.formatPrice(ev.data.nr_valor_tarifa) !== ev.data.nr_valor_tarifa_f)
                this.model.cd_procedimentos[ev.index].nr_valor_tarifa_f = this.formatPrice(ev.data.nr_valor_tarifa)
            this.attChange()
            this.attHospTaxa();
        },
        //Função que estava sendo usada antes da atividade TM-876
        // onCellEditComplete (ev) {
        //     if (this.formatPrice(ev.data.nr_valor_negociado) !== ev.data.nr_valor_negociado_f){
        //         this.model.cd_procedimentos.forEach(item => {
        //             if(item.id == ev.data.id){
        //                 item.nr_valor_negociado_f = this.formatPrice(ev.data.nr_valor_negociado)
        //             }
        //         });
        //     }
        //     if (this.formatPrice(ev.data.nr_valor_tarifa) !== ev.data.nr_valor_tarifa_f){
        //         this.model.cd_procedimentos.forEach(item => {
        //              if(item.id == ev.data.id){
        //                 item.nr_valor_tarifa_f = this.formatPrice(ev.data.nr_valor_tarifa)
        //              }
        //         });
        //     }
        //     this.attChange()
        //    this.attHospTaxa();
        // },
        change_desconto_manual() {
            this.model.nr_valor_hospital = this.nr_restore.hospital;
            this.model.nr_valor_taxa = this.nr_restore.taxa;
        },
        attHospTaxa(){
            this.model.nr_valor_hospital = this.model.cd_procedimentos.filter(item => item.id != 0).reduce((acc, item) => {  
                return acc + +item.nr_valor_negociado
            }, 0)
            this.model.nr_valor_taxa = this.model.cd_procedimentos.filter(item => item.id != 0).reduce((acc, item) => {
                return acc + +item.nr_valor_tarifa
            }, 0)
        },
        attRecebiveis() {
            if(this.model)
                this.model = {
                    ...this.model,
                    nr_valor_opme: 0,
                    nr_valor_anestesista: 0,
                    nr_valor_exames: 0,
                    nr_valor_hemoderivados: 0,
                    nr_valor_fisioterapeuta: 0,
                    nr_valor_honorario: 0,
                    nr_valor_repasse: 0,
                    nr_valor_perfusao: 0,
                    nr_material_cirurgico: 0
                }
        },
        attChange() {
            this.nr_valor_tarifa_f = this.$root.formatPrice(this.model.cd_procedimentos?.filter(item => item.id != 0).reduce((acc, item) => {
                return acc + +item.nr_valor_tarifa
            }, 0))
            this.nr_valor_negociado_f = this.$root.formatPrice(this.model.cd_procedimentos?.filter(item => item.id != 0).reduce((acc, item) => {
                return acc + +item.nr_valor_negociado
            }, 0))
            let auxNegociado = this.model.ar_precos_originais?.reduce((acc, item) => {
                return acc + +item.nr_valor_negociado_original
            }, 0)
            let auxTaxa = this.model.ar_precos_originais?.reduce((acc, item) => {
                return acc + +item.nr_valor_taxa_original
            }, 0)
            this.nr_valor_negociado_original = auxNegociado;
            this.nr_valor_taxa_original = auxTaxa
            this.nr_valor_negociado_original_f = this.$root.formatPrice(auxNegociado)
            this.nr_valor_taxa_original_f = this.$root.formatPrice(auxTaxa)
        },
}
};
</script>
