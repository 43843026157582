<template lang="pug">
    .main-wrapper.cirurgia-salvar

        Dialog.dialogRemover(header="Remover procedimento" :visible.sync='dialogRemover' :modal='true' )
            form(v-if='dialogRemover_data')
                p.ta-center Deseja remover o procedimento <b>{{ dialogRemover_data.ds_descricao }}</b>?
                .ta-center.mt-4
                    Button.p-button-danger(label='Remover' type='button' @click='removerProcedimento(dialogRemover_data._idx)')
        
        Dialog.dialogRemover(header="ATENÇÃO" :modal="true" :visible.sync="dialogRecebiveis")
            .ta-left
                //- span <b> Ao marcar o orçamento como recebível preste atenção aos campos de itens que compõem o valor dos procedimentos  </b>
                span <b> O orçamento so poderá ser marcado como recebível caso sejam ajustados os seguintes campos: </b>
            .ta-left(style="margin-top: 10px; margin-bottom:10px;")
                //- span  Taxas que precisam ser alteradas:
                div(v-for='item in dataRecebiveis')
                    li {{ `${ item.label }: ${ item.value }` }}

            .ta-center
                Button.p-button-success(label='OK'  @click='dialogRecebiveis = false; this.model.ie_recebivel')
       
        Dialog.dialogHistorico(header="Histórico do paciente" :visible.sync='dialogHistorico' :modal='true')
            .ta-center.waitingHistorico(v-if='waitingHistorico')
                ProgressSpinner(strokeWidth='2')
            div(v-else-if='historico.length')

                p <b>Paciente:</b> {{ historico[0].cd_paciente.nm_pessoa_fisica }}

                p.ta-center <b>Outros orçamentos do paciente</b>

                DataTable(:value="historico")

                    Column(headerStyle='width: 20%; font-size:.9em' bodyStyle='font-size: .9em' field='ds_descricao' header='Cirurgia')
                    Column(headerStyle='width: 20%; font-size:.9em' bodyStyle='font-size: .9em' header='Especialista(s)' field='nm_especialista')
                        template(#body='props')
                            span {{props.data.cd_orcamento_cirurgia_procedimento_estabelecimento[0].nm_especialista || ""}}
                    Column(headerStyle='width: 20%; font-size:.9em' bodyStyle='font-size: .9em' field='cd_estabelecimento.nm_fantasia' header='Estabelecimento')
                    Column(headerStyle='width: 8%; font-size:.9em' bodyStyle='font-size: .9em; text-align:center' header='Conta aberta')
                        template(#body='props')
                            span {{ props.data.ie_conta_aberto ? 'Sim' : 'Não' }}
                    Column(headerStyle='width: 10%; font-size:.9em' bodyStyle='font-size: .9em; text-align:right' header='Valor total')
                        template(#body='props')
                            span {{ $root.formatPrice(props.data.nr_valor_total) }}
                    Column(headerStyle='width: 8%; font-size:.9em' bodyStyle='font-size: .9em; text-align:center' header='Habilitado')
                        template(#body='props')
                            span {{ props.data.ie_status ? 'Sim' : 'Não' }}
                    Column(headerStyle='width: 5%; font-size:.9em' bodyStyle='font-size: .9em; text-align:center')
                        template(#body='props')
                            Button.p-button-raised.p-button-rounded(
                                v-tooltip.top="'Editar'"
                                icon="jam jam-write"
                                @click="openEdit(props)")

        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else)
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                    .p-col-9.ta-right(v-if='! isOrcamento')
                        h1.text-header.text-secondary MedClub /
                            <router-link :to='{ name: "cirurgias" }'> Cirurgias</router-link> /
                            <b>{{ model.id ? 'Editar' : 'Adicionar' }}</b>
                    .p-col-9.ta-right(v-else='! isOrcamento')
                        h1.text-header.text-secondary MedClub /
                            <router-link :to='{ name: "orcamentos" }'> Orçamentos</router-link> /
                            <b>{{ model.id ? 'Editar' : 'Adicionar' }}</b>

            form(@submit.prevent='handleSubmit()')
                ProgressBar(v-if='waiting' mode="indeterminate")
                div(v-else)
                    Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } ${ isOrcamento ? 'orçamento' : 'cirurgia' }`")
                        .p-grid.p-fluid.p-align-start

                            .p-col-12(v-if='isOrcamento')
                                .p-grid.p-fluid.p-align-start

                                    .p-col-12.p-md-2
                                        label.form-label Tipo de paciente:
                                        Dropdown(:options='options.tipo_paciente' v-model='tipoPaciente' dataKey='value' optionValue='value' optionLabel='label')

                                    .p-col-12.p-md-6(v-show="tipoPaciente === 'cadastrado'")
                                        BuscarPaciente(ref='buscarPaciente' :cancelarPopup='true')

                                    .p-col-12.p-md-6(v-show="tipoPaciente === 'sem-cadastro'" :class="{ 'form-group--error': submitted && $v.model.nm_paciente.$error }")
                                        label.form-label Paciente (descrição):
                                        InputText(type='text' v-model='$v.model.nm_paciente.$model')
                                        .feedback--errors(v-if='submitted && $v.model.nm_paciente.$error')
                                            .form-message--error(v-if="!$v.model.nm_paciente.required") <b>Paciente</b> é obrigatório.

                                    .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.dt_estimada.$error }")
                                        label.form-label Data estimada:
                                        Calendar(v-model='$v.model.dt_estimada.$model' dateFormat="dd/mm/yy" :locale="ptbr" :manualInput='false')
                                        .feedback--errors(v-if='submitted && $v.model.dt_estimada.$error')
                                            .form-message--error(v-if="!$v.model.dt_estimada.required") <b>Data estimada</b> é obrigatório.

                                    .p-col-12
                                        label.form-label Observação:
                                        Textarea(type='text' v-model='model.ds_observacao' placeholder='(Opcional)' :autoResize="true" rows="3")
                                        

                            .p-col-12.p-md-8
                                .p-grid.p-fluid.p-align-end
                                    .p-col-12.p-md-6(:class="{ 'form-group--error': submitted && $v.model.ds_descricao.$error }")
                                        label.form-label Descrição:
                                        InputText(type='text' v-model='$v.model.ds_descricao.$model')
                                        .feedback--errors(v-if='submitted && $v.model.ds_descricao.$error')
                                            .form-message--error(v-if="!$v.model.ds_descricao.minLength") <b>Descrição</b> deve ter pelo menos 2 caracteres.
                                            .form-message--error(v-if="!$v.model.ds_descricao.required") <b>Descrição</b> é obrigatório.

                                    .p-col-12.p-md-6(:class="{ 'form-group--error': submitted && $v.model.cd_estabelecimento.$error }")
                                        label.form-label Estabelecimento:
                                        Dropdown(v-model='$v.model.cd_estabelecimento.$model' :options='options.estabelecimentos' dataKey='id' :filter='options.estabelecimentos.length > 0'
                                            optionLabel='nm_fantasia' optionValue='id' placeholder='Selecione...' @change="getEspecialistas()")
                                        .feedback--errors(v-if='submitted && $v.model.cd_estabelecimento.$error')
                                            .form-message--error(v-if="!$v.model.cd_estabelecimento.required") <b>Estabelecimento</b> é obrigatório.

                                    .p-col-12.p-md-6(v-if="!isOrcamento")
                                        ProgressBar(v-if='waitingEspecialidades' mode="indeterminate")
                                        div(v-else)
                                            label.form-label Especialidades:
                                            MultiSelect(v-model='model.cd_especialidades' :options='options.especialidades'
                                                optionLabel='text' optionValue='value' dataKey='value' filter placeholder='Selecione')

                                    .p-col-12.p-md-6(v-if='isOrcamento')
                                        label.form-label Situação:
                                        Dropdown(v-model='model.cd_orcamento_situacao' :options='options.cd_orcamento_situacao' dataKey='value'
                                            optionLabel='label' optionValue='value')

                                    // .p-col-12.p-md-6(v-if='isOrcamento && model.ie_recebivel')
                                    //     label.form-label Itens Recebíveis:
                                    //     MultiSelect(v-model='aux_ar_itens_recebiveis' :options='ar_itens_recebiveis'
                                    //         optionLabel='label' optionValue='value' dataKey='value' filter placeholder='Selecione')
                                    //     .feedback--errors(v-if='submitted && $v.aux_ar_itens_recebiveis.$error')
                                    //         .form-message--error(v-if="!$v.aux_ar_itens_recebiveis.required") <b>Itens Recebíveis</b> são obrigatórios quando o orçamento é recebível.

                                    .p-col-12.p-md-6(v-if='!isOrcamento' :class="{ 'form-group--error': submitted && $v.model.ie_tipo_preco.$error }")
                                        label.form-label Tipo de Preço:
                                        Dropdown(v-model='model.ie_tipo_preco' :options='options.ie_tipo_preco' dataKey='value'
                                            optionLabel='label' optionValue='value')
                                        .feedback--errors(v-if='submitted && $v.model.ie_tipo_preco.$error')
                                            .form-message--error(v-if="!$v.model.ie_tipo_preco.required") <b>Tipo de Preço</b> é obrigatório.

                            .p-col-12.p-md-4
                                .p-grid.p-fluid.p-align-end
                                    .p-col-6.p-sm-3.p-md-6.p-xl-3.ta-center(v-if="!isOrcamento")
                                        label.form-label App cliente:
                                        InputSwitch(type="boolean" v-model='model.ie_app_cliente')
                                    .p-col-6.p-sm-3.p-md-6.p-xl-3.ta-center(v-if="!isOrcamento")
                                        label.form-label App médico:
                                        InputSwitch(type="boolean" v-model='model.ie_app_medico')
                                    .p-col-3.p-sm-3.p-md-4.p-xl-3.ta-center(:class="{'p-sm-3 p-xl-3': isOrcamento}")
                                        label.form-label Conta aberta:
                                        InputSwitch(type="boolean" v-model='model.ie_conta_aberto')
                                    .p-col-3.p-sm-3.p-md-3.p-xl-3.ta-center(:class="{'p-sm-3 p-xl-3': isOrcamento}")
                                        label.form-label Pacote:
                                        InputSwitch(type="boolean" v-model='model.ie_pacote')
                                    .p-col-3.p-sm-3.p-md-3.p-xl-3.ta-center(:class="{'p-sm-3 p-xl-3': isOrcamento}")
                                        label.form-label Habilitado:
                                        InputSwitch(type="boolean" v-model='model.ie_status')
                                    .p-col-3.p-sm-3.p-md-3.p-xl-3.ta-center(v-if="isOrcamento" :class="{'p-sm-3 p-xl-3': isOrcamento}")
                                        label.form-label Recebível:
                                        InputSwitch(type="boolean" v-model='model.ie_recebivel' @change='if(model.ie_recebivel && verificaTaxas()) dialogRecebiveis=true;')

                            .p-col-12(v-if='!isOrcamento')
                                label.form-label Mensagem App:
                                Textarea(v-model='model.ds_observacao_app' :autoResize="true" rows="4")

                            .p-col-6.mt-3
                                span <b>Usuario que criou: </b> {{ model.nm_usuario_cri }}
                            .p-col-6
                                span <b>Data da criação: </b> {{ model.dt_criado_f }}
                            .p-col-6
                                span <b>Usuario que editou: </b> {{ model.nm_usuario_edi }}
                            .p-col-6
                                span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}

                            .p-col-12.mt-2(v-show='formProcedimento')
                                SalvarProcedimento(ref='salvarProcedimento')

                            .p-col-12.mt-2(v-show='! formProcedimento')
                                .p-grid.p-fluid
                                    .p-col-12.mt-2(v-if='model.procedimentos')
                                        Panel(header='Procedimentos')
                                            .p-grid.p-fluid
                                                .p-col-12(v-for='(procedimento, idx) in model.procedimentos' :key='idx')
                                                    Card
                                                        template(#content)
                                                            .p-grid.p-fluid.p-align-center.card-procedimento
                                                                .p-col-2.ta-right
                                                                    b Descrição:
                                                                .p-col-10
                                                                    input.p-inputtext(readonly :value='procedimento.ds_descricao')
                                                                .p-col-2.ta-right
                                                                    b Qtd. procedimentos:
                                                                .p-col-2
                                                                    input.p-inputtext(readonly :value='procedimento.qtd_procedimento')
                                                                .p-col-2.ta-right
                                                                    b Hospital:
                                                                .p-col-2
                                                                    input.p-inputtext(readonly :value='$root.formatPrice(procedimento.nr_valor_hospital)')
                                                                .p-col-2.ta-right
                                                                    b Material cirúrgico:
                                                                .p-col-2
                                                                    input.p-inputtext(readonly :value='$root.formatPrice(procedimento.nr_material_cirurgico)')
                                                                .p-col-2.ta-right
                                                                    b Anestesia:
                                                                .p-col-2
                                                                    input.p-inputtext(readonly :value='$root.formatPrice(procedimento.nr_valor_anestesista)')
                                                                .p-col-2.ta-right
                                                                    b OPME:
                                                                .p-col-2
                                                                    input.p-inputtext(readonly :value='procedimento.opme_f')
                                                                .p-col-2.ta-right
                                                                    b Exames:
                                                                .p-col-2
                                                                    input.p-inputtext(readonly :value='procedimento.exames_f')
                                                                .p-col-2.ta-right
                                                                    b Hemoderivados:
                                                                .p-col-2
                                                                    input.p-inputtext(readonly :value='procedimento.hemoderivados_f')
                                                                .p-col-2.ta-right
                                                                    b Repasse:
                                                                .p-col-2
                                                                    input.p-inputtext(readonly :value='$root.formatPrice(procedimento.nr_valor_repasse)')
                                                                .p-col-2.ta-right
                                                                    b Perfusão:
                                                                .p-col-2
                                                                    input.p-inputtext(readonly :value='$root.formatPrice(procedimento.nr_valor_perfusao)')
                                                                .p-col-2.ta-right
                                                                    b Fisioterapia:
                                                                .p-col-2
                                                                    input.p-inputtext(readonly :value='$root.formatPrice(procedimento.nr_valor_fisioterapeuta)')
                                                                .p-col-2.ta-right
                                                                    b Honorários:
                                                                .p-col-2
                                                                    input.p-inputtext(readonly :value='$root.formatPrice(procedimento.nr_valor_honorario)')
                                                                .p-col-2.ta-right
                                                                    b Taxa Medclub:
                                                                .p-col-2
                                                                    input.p-inputtext(readonly :value='procedimento.taxa_f')
                                                                .p-col-2.ta-right
                                                                    b Porte:
                                                                .p-col-2
                                                                    input.p-inputtext(readonly :value="procedimento.ie_porte === 'G' ? 'Grande/Médio' : 'Pequeno'")
                                                                .p-col-2.ta-right
                                                                    b Especialista:
                                                                .p-col-2
                                                                    ProgressBar(v-if='waitingEspecialista' mode="indeterminate")
                                                                    input.p-inputtext(v-else-if='isOrcamento' readonly :value="`${procedimento.nm_especialista} ${!!procedimento.cd_especialista ? 'as' : '(sem cadastro)'}`")
                                                                    input.p-inputtext(v-else readonly :value="procedimento.cd_especialista ? procedimento.cd_especialista.nm_especialista : '(Sem especialista)'")
                                                                .p-col-4
                                                                .p-col-4
                                                                .p-col-4
                                                                .p-col-4
                                                                // .p-col-4.ta-center
                                                                //     b Recebíveis:
                                                                
                                                                // .p-col-12.ta-right(style='display:flex; flex-direction: row; justify-content: center; margin-top:15px')
                                                                //    .p-grid.p-fluid(v-for='(recebivel,idx) in procedimento.ar_itens_recebiveis' :key='idx')
                                                                //         div(style='background-color: #cccccc; height:30px; border-radius: 15px; margin-left:20px; padding-right:8px; padding-left:8px; display: flex; justify-content: center; align-items: center; ' ) 
                                                                //             p {{mostrarRecebiveis(recebivel)}}

                                                                // .p-col-12.mt-2.ta-right
                                                                //     span 
                                                                //         b VALOR RECEBÍVEL :{{ calcRecebiveis(procedimento) }}
                                                    
                                                                .p-col-2.ta-right
                                                                    b Acomodações:
                                                                .p-col-10
                                                                    div(style='width:100%;height:1px;background-color:#a6a6a6')

                                                                .p-col-2.ta-right
                                                                    b Apt. IND.:
                                                                .p-col-1
                                                                    input.p-inputtext(readonly :value='procedimento.qtd_diaria_apartamento_individual')
                                                                .p-col-2.ta-right
                                                                    b Apt. DUPLO:
                                                                .p-col-1
                                                                    input.p-inputtext(readonly :value='procedimento.qtd_diaria_apartamento_duplo')
                                                                .p-col-2.ta-right
                                                                    b UTI:
                                                                .p-col-1
                                                                    input.p-inputtext(readonly :value='procedimento.qtd_diaria_uti')
                                                                .p-col-2.ta-right
                                                                    b EXTERNO (Day Clinic):
                                                                .p-col-1
                                                                    input.p-inputtext(readonly :value='procedimento.qtd_diaria_dia_clinica')
                                                                .p-col-12.mt-2.ta-right
                                                                    span 
                                                                        b VALOR :{{calcValorItem(procedimento)}}
                                                        template(#footer)
                                                            .ta-right
                                                                
                                                                Button.ml-4.p-button-rounded.p-button-raised(icon='jam jam-write' type='button'
                                                                    @click='editProcedimento(idx)')
                                                                Button.ml-1.p-button-rounded.p-button-raised.p-button-danger(icon='jam jam-trash' type='button'
                                                                    @click='procedimento._idx = idx; dialogRemover_data = procedimento; dialogRemover = true')
                                            .p-grid.p-fluid
                                                .p-col-10
                                                .p-col-2.ta-right
                                                    span 
                                                        b VALOR TOTAL:{{calcValorTotal()}}

                                    .p-col-12.mt-2(v-else)
                                        Panel(header='Procedimentos')
                                            p.ta-center.m-0 Não existem procedimentos.

                                    .p-col-12.p-md-12
                                        label.form-label Observação para Paciente:
                                        Textarea(type='text' v-model='model.ds_observacao_paciente' placeholder='(Opcional)' :autoResize="true" rows="3")
                                    
                                    .p-col-12(v-if='! formProcedimento')

                                        .p-grid.p-fluid

                                            .p-col-12.p-md-6
                                                Button(v-if='! model.procedimentos.length' icon='jam jam-plus' label='Adicionar procedimento' style='font-size:13px;max-width:240px' type='button' @click='openAdicionarProcedimento()')

                                            .p-col-12.p-md-6.ta-right
                                                div(v-if='waitingOrcamento')
                                                    ProgressSpinner.waitingOrcamento(strokeWidth='6')
                                                Button(v-else icon='jam jam-check' :label="labelSubmit()" style='font-size:13px;max-width:240px;font-weight:700' type="submit")

                             

</template>

<style lang="scss">

.cirurgia-salvar {
    .p-dialog {
        overflow: visible;
        width: 98%;
        max-width: 720px;
    }
     .dialog-opcoes-recebiveis {
        width: 400px;
        max-width: 96%;
    }
    .card-procedimento {
        font-size: 0.96em;
        .p-col-1,
        .p-col-2,
        .p-col-4,
        .p-col-10,
        .p-col-12 {
            padding: 0.2em;
        }
        .p-inputtext {
            font-size: 0.96em;
            padding: 0.2em;
        }
    }

    .dialogHistorico {
        max-width: 70%;
        min-width: 360px;
        max-height: 480px;
    }

    .waitingCirurgias-wrapper {
        text-align: center;
        padding: 80px 0;
        .p-progress-spinner {
            width: 60px;
            height: auto;
        }
    }
    .waitingOrcamento {
        width: 27px;
        height: auto;
    }
    .waitingHistorico {
        padding: 40px 0;
        .p-progress-spinner {
            width: 60px;
            height: auto;
        }
    }
    .aux-1 {
        text-align: center;
        @media all and (max-width: 576px) {
            text-align: left;
        }
    }
    .p-inputtext[disabled="disabled"] {
        color: #000;
        font-weight: 600;
        background-color: #ddd;
    }
    .p-picklist-source-controls,
    .p-picklist-target-controls {
        display: none;
    }
    .p-picklist-source-wrapper,
    .p-picklist-target-wrapper {
        width: 45%;
        @media all and (max-width: 576px) {
            width: 100%;
        }
    }
    .p-picklist-buttons-cell {
        text-align: center;
        .p-button {
            display: none !important;
            &:first-of-type,
            &:nth-of-type(3) {
                display: inline-block !important;
            }
        }
    }
    .text-box {
        margin: 0;
        font-size: 16px;
    }
    .input-sexo .p-button {
        width: 50% !important;
    }
}
</style>

<script>
import ProgressBar from "primevue/progressbar";
import ProgressSpinner from "primevue/progressspinner";
import Panel from "primevue/panel";
import InputText from "primevue/inputtext";
import InputMask from "primevue/inputmask";
import InputSwitch from "primevue/inputswitch";
import Password from "primevue/password";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import SelectButton from "primevue/selectbutton";
import Tooltip from "primevue/tooltip";
import PickList from "primevue/picklist";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Paginator from "primevue/paginator";
import Dialog from "primevue/dialog";
import Card from "primevue/card";
import Calendar from "primevue/calendar";
import MultiSelect from "primevue/multiselect";
import Textarea from "primevue/textarea";

import BuscarPaciente from "../Paciente/BuscarPaciente";
import SalvarProcedimento from "./SalvarProcedimento";
import { Cirurgia, Estabelecimento, Orcamento, ProcedimentosEstabelecimento } from "./../../middleware";
import { required, requiredIf, minLength } from "vuelidate/lib/validators";
import { Especialista } from "../../middleware";
import { pCalendarLocale_ptbr } from "./../../utils";
import moment from "moment";
import Protocolo from "../../middleware/controllers/Protocolo";

export default {
    components: {
        ProgressBar,
        Panel,
        InputText,
        Button,
        Password,
        ProgressSpinner,
        Calendar,
        Textarea,
        InputMask,
        SelectButton,
        Dropdown,
        PickList,
        DataTable,
        Column,
        MultiSelect,
        Paginator,
        Dialog,
        BuscarPaciente,
        InputSwitch,
        SalvarProcedimento,
        Card
    },
    directives: { tooltip: Tooltip },
    created() {
        this.refresh();
    },
    watch: {
        "model.procedimentos": function(procedimentos) {
            procedimentos.forEach(procedimento => {
                procedimento.opme_f = `${this.$root.formatPrice(
                    procedimento.nr_valor_opme
                )}`;
                if (procedimento.ds_opme_descricao)
                    procedimento.opme_f += ` (${procedimento.ds_opme_descricao})`;
                procedimento.taxa_f = `${this.$root.formatPrice(
                    procedimento.nr_valor_taxa
                )}`;
                if (procedimento.ds_taxa_descricao)
                    procedimento.taxa_f += ` (${procedimento.ds_taxa_descricao})`;
                procedimento.exames_f = `${this.$root.formatPrice(
                    procedimento.nr_valor_exames
                )}`;
                if (procedimento.ds_exames_descricao)
                    procedimento.exames_f += ` (${procedimento.ds_exames_descricao})`;
                procedimento.hemoderivados_f = `${this.$root.formatPrice(
                    procedimento.nr_valor_hemoderivados
                )}`;
                if (procedimento.ds_hemoderivados_descricao)
                    procedimento.hemoderivados_f += ` (${procedimento.ds_hemoderivados_descricao})`;
                procedimento.cd_procedimentos.forEach(cd => {
                    cd.nr_valor_negociado_f = `${this.$root.formatPrice(
                        cd.nr_valor_negociado
                    )}`
                    cd.nr_valor_tarifa_f = `${this.$root.formatPrice(
                        cd.nr_valor_tarifa
                    )}`
                })
            });
        },
        "model.ie_conta_aberto": function(val) {
            this.model.ie_pacote = !val;
        },
        "model.ie_pacote": function(val) {
            this.model.ie_conta_aberto = !val;
        },
    
        "aux_ar_itens_recebiveis": function(val) {
        
            if(val && val.length > 0)
                this.model.procedimentos[0].ar_itens_recebiveis = val.map(item => (this.ar_itens_recebiveis.find(i => item == i.value)?.name))
            else {
                this.model.procedimentos[0].ar_itens_recebiveis = [];
            }
        },
        "model.proceditmentos": function(val){
            this.aux_ar_itens_recebiveis = val[0].ar_itens_recebiveis.length > 0?
            val[0].ar_itens_recebiveis.map(item => this.ar_itens_recebiveis.find(i => i.name == item).value)
            : [];
        },
        waiting: function(val) {
            if (!val) {
                window.setTimeout(() => {
                    if (this.$refs.buscarPaciente)
                        this.$refs.buscarPaciente.onSelectPessoaFisica = () => {
                            this.onSelectPessoaFisica();
                        };
                });
            }
        },
        especialistas: function(val) {
            this.model.procedimentos.forEach(p => {
                if (!p.nm_especialista) p.nm_especialista = "";
                if (p.cd_especialista) {
                    const especialista_find = val.find(
                        e => e.id === p.cd_especialista.id
                    );
                    if (especialista_find)
                        p.cd_especialista = Object.assign(
                            {},
                            especialista_find
                        );
                    else {
                        p.cd_especialista = null;
                        p.nm_especialista = "";
                    }
                }
            });
        }
    },
    data() {
        return {
            model: {
                dt_estimada: null,
                ds_observacao: null,
                ds_observacao_paciente: null,
                cd_paciente: null,
                ds_descricao: null,
                ds_observacao_app: null,
                cd_estabelecimento: null,
                ie_status: true,
                ie_app_cliente: true,
                ie_app_medico: true,
                ie_pacote: false,
                ie_recebivel: false,
                ie_conta_aberto: true,
                ie_tipo_preco: "A",
                cd_orcamento_situacao: 1,
                nm_paciente: null,
                nm_usuario_cri: null,
                nm_usuario_edi: null,
                cd_especialidades: [],
                procedimentos: []
            },
            filters: {
                procedimentos: ""
            },
            options: {
                estabelecimentos: [],
                especialistas: [],
                especialidades: [],
                tipo_paciente: [
                    { label: "Cadastrado", value: "cadastrado" },
                    { label: "Sem cadastro", value: "sem-cadastro" }
                ],
                cd_orcamento_situacao: [
                    { label: "Em elaboração", value: 1 },
                    { label: "Pagamento pendente", value: 2 }
                ],
                ie_tipo_preco: [
                    { label: "A partir de", value: "A" },
                    { label: "Valor Fixo", value: "F" },
                    { label: "Não exibir", value: "N" }
                ]
            },
            aux_ar_itens_recebiveis: [],
            ar_itens_recebiveis: [
                // { label:'Hospital', name: 'nr_valor_hospital', value: 0 },
                { label: 'Material cirúrgico', name: 'nr_material_cirurgico', value: 1 },
                { label:'Anestesia', name: 'nr_valor_anestesista', value: 2 },
                { label: 'Valor OPME', name: 'nr_valor_opme', value: 3 },
                { label:'Repasse', name: 'nr_valor_repasse', value: 4 },
                { label:'Perfusão', name: 'nr_valor_perfusao', value: 5 },
                { label:'Fisioterapia', name: 'nr_valor_fisioterapeuta', value: 6 },
                // { label:'Taxa', name: 'nr_valor_taxa', value: false },
                { label:'Hemoderivados', name: 'nr_valor_hemoderivados', value: 7 },
                { label:'Exames', name: 'nr_valor_exames', value: 8 },
                { label:'Honorários', name: 'nr_valor_honorario', value: 9 },
            ],
            ptbr: pCalendarLocale_ptbr,
            waiting: true,
            waitingOrcamento: false,
            waitingEspecialista: false,
            waitingEspecialidades: false,
            submitted: false,
            formProcedimento: false,
            dialogRemover: false,
            dialogRecebiveis: false,
            dialogRemover_data: null,
            isOrcamento: false,
            dataRecebiveis: null,
            editOrcamento: false,
            tipoPaciente: "cadastrado",
            especialistas: [],
            labelSubmit() {
                return this.isOrcamento
                    ? this.editOrcamento
                        ? "Salvar"
                        : "Criar orçamento"
                    : "Salvar";
            },
            dialogHistorico: false,
            waitingHistorico: false,
            historico: []
        };
    },
    validations() {
        let v = {
            model: {
                ds_descricao: { required, minLength: minLength(2) },
                dt_estimada: { required: requiredIf(() => this.isOrcamento) },
                cd_estabelecimento: { required },
                ie_tipo_preco: {
                    required: requiredIf(() => !this.isOrcamento)
                },
                nm_paciente: {
                    required: requiredIf(
                        () => this.tipoPaciente === "sem-cadastro"
                    )
                }
            },
        };

        return v;
    },
    methods: {
        refresh() {
            this.waiting = true;

            let id = 0;
            if (!isNaN(this.$route.params.id))
                id = parseInt(this.$route.params.id);

            Estabelecimento.findAllClean().then(response => {
                if (response.status === 200)
                    this.options.estabelecimentos = response.data;
                if (this.$route.query.orcamento) {
                    this.isOrcamento = true;
                    if (this.$route.query.edit) this.editOrcamento = true;
                }

                if (this.isOrcamento && this.editOrcamento) {
                    if (id != 0) {
                        Orcamento.find(id).then(response => {
                            if (([200, 201]).includes(response.status)) {
                                let keys = Object.keys(this.model);
                                keys.forEach(
                                    key =>
                                        (this.model[key] = response.data[key])
                                );
                                this.model.id = id;
                                this.model.cd_orcamento_situacao = this.model.cd_orcamento_situacao.id;
                                this.model.cd_estabelecimento = this.model.cd_estabelecimento.id;
                                this.getEspecialistas();
                                this.model.dt_criado_f = moment(
                                    response.data.dt_criado
                                ).format("DD-MM-YYYY");
                                if (response.data.dt_atualizado) {
                                    this.model.dt_atualizado_f = moment(
                                        response.data.dt_atualizado
                                    ).format("DD-MM-YYYY");
                                }

                                if (
                                    response.data
                                        .cd_orcamento_cirurgia_procedimento_estabelecimento
                                        .length
                                ) {
                                    this.model.procedimentos =
                                        response.data.cd_orcamento_cirurgia_procedimento_estabelecimento;
                                    this.model.procedimentos.forEach(p => {
                                        p.cd_procedimentos = p.cd_procedimento_estabelecimentos
                                    });
                                }
                                if (this.model.dt_estimada)
                                    this.model.dt_estimada = moment(this.model.dt_estimada)._d;
                                if (!this.model.cd_paciente)
                                    this.tipoPaciente = "sem-cadastro";

                                if (response.data.cd_paciente)
                                    setTimeout(() => {
                                        this.$refs.buscarPaciente.filtro.selected.value =
                                            response.data.cd_paciente.nr_cpf;
                                        this.$refs.buscarPaciente.buscar();
                                    });
                                this.aux_ar_itens_recebiveis = response.data.cd_orcamento_cirurgia_procedimento_estabelecimento[0].ar_itens_recebiveis?
                                response.data.cd_orcamento_cirurgia_procedimento_estabelecimento[0].ar_itens_recebiveis.map(item => (this.ar_itens_recebiveis.find(i => i.name == item)?.value))
                                : [];
                            }
                            this.waiting = false;
                        });
                    } else this.waiting = false;
                } else {
                    this.getEspecialidades();
                    if (id != 0) {
                        Cirurgia.find(id).then(response => {
                            if (response.status === 200) {
                                let keys = Object.keys(this.model);
                                keys.forEach(
                                    key =>
                                        (this.model[key] = response.data[key])
                                );
                                this.model.id = id;
                                this.model.cd_estabelecimento = this.model.cd_estabelecimento.id;
                                this.getEspecialistas();
                                this.model.dt_criado_f = moment(
                                    response.data.dt_criado
                                ).format("DD-MM-YYYY");
                                if (response.data.dt_atualizado) {
                                    this.model.dt_atualizado_f = moment(
                                        response.data.dt_atualizado
                                    ).format("DD-MM-YYYY");
                                }
                                if (!this.model.cd_especialidades)
                                    this.model.cd_especialidades = [];
                                else
                                    this.model.cd_especialidades = this.model.cd_especialidades.map(
                                        esp => esp.id
                                    );
                                if (
                                    response.data
                                        .cd_cirurgia_procedimento_estabelecimento
                                        .length
                                ) {
                                    this.model.procedimentos =
                                        response.data.cd_cirurgia_procedimento_estabelecimento;
                                    this.model.procedimentos.forEach(p => {
                                        p.cd_procedimentos = p.cd_procedimento_estabelecimentos.map(item => ({
                                            ...item,
                                            nr_valor_taxa_original: item.nr_valor_tarifa,
                                            nr_valor_negociado_original: item.nr_valor_negociado
                                        }))
                                    });
                                }
                                if (this.isOrcamento)
                                    this.model.cd_orcamento_situacao = 1;
                            }
                            this.waiting = false;
                        });
                    } else this.waiting = false;
                }
            });
        },
        openAdicionarProcedimento() {
            if (this.model.cd_estabelecimento) {
                this.$refs.salvarProcedimento.reset();
                this.formProcedimento = true;
            } else
                this.$toast.error(`Selecione o estabelecimento`, {
                    duration: 3000
                });
        },
        removerProcedimento(idx) {
            this.model.procedimentos.splice(idx, 1);
            this.dialogRemover = false;
        },
        editProcedimento(idx) {
            
            this.$refs.salvarProcedimento.reset();
            let auxProcedimento = Object.assign(
                {},
                this.model.procedimentos[idx]
            );

            let floatFields = [
                "nr_valor_opme",
                "nr_valor_taxa",
                "nr_valor_fisioterapeuta",
                "nr_valor_honorario",
                "nr_valor_repasse",
                "nr_valor_hospital",
                "nr_valor_anestesista",
                "nr_valor_perfusao",
                "nr_material_cirurgico",
                "nr_valor_exames",
                "nr_valor_hemoderivados"
            ];
            floatFields.forEach(field => {
                auxProcedimento[field] = parseFloat(
                    auxProcedimento[field]
                );
            });

            let auxids = auxProcedimento.cd_procedimentos.map(item => (item.id))
            auxProcedimento.ar_precos_originais = [];

            if (auxProcedimento.cd_especialista)
                auxProcedimento.cd_especialista = auxProcedimento.cd_especialista.id;

            auxProcedimento._idx = idx;

            ProcedimentosEstabelecimento.findAll({ cd_procedimento_estabelecimento: auxids }).then(response => {
                if(([200, 201]).includes(response.status)){
                    auxProcedimento.cd_procedimentos.forEach(item => {
                        let auxProc = response.data.find(i => i.id == item.id)
                        if(auxProc) {
                            item.nr_valor_taxa_original = +auxProc.nr_valor_tarifa;
                            item.nr_valor_negociado_original= +auxProc.nr_valor_negociado,
                            item.nr_valor_taxa_original_f = this.$root.formatPrice(+auxProc.nr_valor_tarifa),
                            item.nr_valor_negociado_original_f = this.$root.formatPrice(+auxProc.nr_valor_negociado),
                            item.nr_valor_negociado = +item.nr_valor_negociado
                            item.nr_valor_tarifa = +item.nr_valor_tarifa

                        }
                        auxProcedimento.ar_precos_originais.push(item)
                    })
                }
            }).then(() => {
                this.$refs.salvarProcedimento.model = Object.assign(
                    {},
                    auxProcedimento
                );
                this.formProcedimento = true;
            })

        },
        onSelectPessoaFisica() {
            this.dialogHistorico = true;
            this.waitingHistorico = true;
            this.historico = [];
            Orcamento.findAll({
                nr_cpf: this.$refs.buscarPaciente.model.nr_cpf
            }).then(response => {
                if (response.status === 200) {
                    if (response.data.length)
                        this.historico = this.model.id
                        ? response.data.filter(i => i.id != this.model.id).filter(i => i.cd_orcamento_situacao.ds_sigla != 'CA')
                        : response.data.filter(i => i.cd_orcamento_situacao.ds_sigla != 'CA');
                    if(this.historico.length == 0) {
                        this.dialogHistorico = false;
                        this.$toast.info("Paciente sem orçamentos ativos", {
                            duration: 3000
                        });
                    }
                }
                this.waitingHistorico = false;
            });
        },
        getEspecialistas() {
            this.waitingEspecialista = true;
            Especialista.findByEstabelecimento(
                this.model.cd_estabelecimento
            ).then(response => {
                this.waitingEspecialista = false;
                if (response.status === 200) this.especialistas = response.data;
            });
        },
        getEspecialidades() {
            this.waitingEspecialidades = true;
            Protocolo.getEspecialidades().then(response => {
                this.waitingEspecialidades = false;
                if ([200, 201, 204].includes(response.status)) {
                    this.options.especialidades = response.data.map(esp => {
                        return { text: esp.nm_especialidade, value: esp.id };
                    });
                }
            });
        },
        openEdit(props) {
            this.dialogHistorico = false;
            this.$router.push(
                `/cirurgias/salvar/${props.data.id}/?orcamento=true&edit=true`
            );
            this.$forceUpdate();
            this.refresh();
        },
        handleSubmit() {

            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) return 0;

            let dataSend = Object.assign({}, this.model);

            dataSend.procedimentos[0].cd_procedimentos = dataSend.procedimentos[0].cd_procedimentos.filter(item => item.id != 0)
            
            if (this.tipoPaciente == "sem-cadastro")
                dataSend.cd_paciente = null;

            dataSend.procedimentos.forEach(procedimento => {
                delete procedimento.dt_estimada;

                if (procedimento.cd_especialista) {
                    if (
                        procedimento.cd_especialista.nm_especialista ===
                        "(Sem especialista)"
                    ) {
                        procedimento.cd_especialista.id = null;
                    }
                    procedimento.cd_especialista =
                        procedimento.cd_especialista.id;
                }
                procedimento.cd_procedimentos.forEach(item => {
                    if(!item.ie_tabela_preco) item.ie_tabela_preco = false
                })
            });

            if (!this.isOrcamento) {
                this.waiting = true;
                Cirurgia.save(dataSend).then(response => {
                    if ([200, 201, 204].includes(response.status)) {
                        if (!dataSend.id)
                            this.$router.push({ name: "cirurgias" });
                        this.$toast.success("Cadastro salvo com sucesso", {
                            duration: 3000
                        });
                        this.model.procedimentos.forEach(p => {
                            p.cd_especialista = this.especialistas.find(
                                e => e.id === p.cd_especialista
                            );
                        });
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == "string")
                            this.$toast.error(response.data.detail, {
                                duration: 3000
                            });
                        else
                            response.data.detail.forEach(msg =>
                                this.$toast.error(msg, { duration: 3000 })
                            );
                    }
                    this.waiting = false;
                });
            } else {
                if (this.tipoPaciente === "cadastrado") {
                    this.model.nm_paciente = null;
                    if (!this.$refs.buscarPaciente.cd_pessoa_fisica) {
                        this.$toast.error("Selecione um paciente", {
                            duration: 3000
                        });
                        return 0;
                    }
                    dataSend.cd_paciente = this.$refs.buscarPaciente.cd_pessoa_fisica;
                } else {
                    this.model.cd_paciente = null;
                    if (!this.model.nm_paciente) {
                        this.$toast.error(
                            "Preencha uma identificação para o paciente",
                            { duration: 3000 }
                        );
                        return 0;
                    }
                }

                dataSend.dt_estimada = moment(dataSend.dt_estimada).format(
                    "YYYY-MM-DD"
                );
                

                if (!this.editOrcamento) {
                    delete dataSend.id;
                    dataSend.procedimentos[0].cd_procedimentos[0].ie_tabela_preco = false;
                } 

                this.waitingOrcamento = true;
                Orcamento.save(dataSend).then(response => {
                    if ([200, 201, 204].includes(response.status)) {
                        this.$toast.success("Cadastro salvo com sucesso", {
                            duration: 3000
                        });
                        if (!this.editOrcamento)
                            this.$router.push({ name: "orcamentos" });
                        this.model.procedimentos.forEach(p => {
                            p.cd_especialista = this.especialistas.find(
                                e => e.id === p.cd_especialista
                            );
                        });
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == "string")
                            this.$toast.error(response.data.detail, {
                                duration: 3000
                            });
                        else
                            response.data.detail.forEach(msg =>
                                this.$toast.error(msg, { duration: 3000 })
                            );
                    }
                    this.waitingOrcamento = false;
                });
            }
        },
        calcValorItem(item) {
            return this.$root.formatPrice(
                +item.nr_material_cirurgico +
                    +item.nr_valor_anestesista +
                    +item.nr_valor_exames +
                    +item.nr_valor_fisioterapeuta +
                    +item.nr_valor_hemoderivados +
                    +item.nr_valor_honorario +
                    +item.nr_valor_hospital +
                    +item.nr_valor_opme +
                    +item.nr_valor_perfusao +
                    +item.nr_valor_repasse +
                    +item.nr_valor_taxa
            );
        },
        calcValorTotal() {
            return this.model.procedimentos? this.$root.formatPrice(
                this.model.procedimentos.reduce((acc, item) => {
                    return (
                        +item.nr_material_cirurgico +
                        +item.nr_valor_anestesista +
                        +item.nr_valor_exames +
                        +item.nr_valor_fisioterapeuta +
                        +item.nr_valor_hemoderivados +
                        +item.nr_valor_honorario +
                        +item.nr_valor_hospital +
                        +item.nr_valor_opme +
                        +item.nr_valor_perfusao +
                        +item.nr_valor_repasse +
                        +item.nr_valor_taxa
                    );
                }, 0)
            ) : 0;
        },
        calcRecebiveis(procedimento){
            return procedimento.ar_itens_recebiveis? this.$root.formatPrice(procedimento.ar_itens_recebiveis.reduce((acc, item) => {
                return +procedimento[item] + acc;
            }, 0)):0;
        },
        mostrarRecebiveis(item) {
            return this.ar_itens_recebiveis.find(j => j.name == item)?.label
          
        },
        verificaTaxas() {
            let aux = this.ar_itens_recebiveis.filter(item => this.model.procedimentos[0][item.name] != 0).map(item => ({
                label: item.label,
                value: this.$root.formatPrice(+this.model.procedimentos[0][item.name])
            }))
            if(aux.length) this.model.ie_recebivel = false;
            this.dataRecebiveis = aux.length > 0? aux : null;
            return this.dataRecebiveis;
        }
    }
};
</script>
